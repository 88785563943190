<template>
  <div
    data-testid="exam-list-item"
    :class="['exams-list-item', {'exams-list-item--unavailable': !isExamStatusAvailable}]">
    <div class="exams-list-item__process-tag d-none d-md-inline-block">
      <span class="exams-list-item__process-tag-label">Processo&nbsp;</span>
      <span class="exams-list-item__process-tag-content font-weight-bold">
        {{ processData.id }}
      </span>
    </div>
    <div class="exams-list-item__status-tag exam-status text-center">
      <span class="exams-list-item__status-tag-icon"></span>
      <span class="exams-list-item__status-tag-text">
        {{ isExamStatusAvailable ? 'Resultado disponível' : 'Resultado indisponível' }}
      </span>
    </div>
    <div class="exams-list-item__date-tag">
      <template v-if="isExamStatusAvailable">
        <span class="exams-list-item__date-tag-label d-none d-lg-inline-block">
          Realizado em&nbsp;
        </span>
        <span class="exams-list-item__date-tag-content">{{ formattedDateTimeConclusion }}</span>
      </template>
    </div>
    <div class="exams-list-item__content-wrapper">
      <div class="exams-list-item__info-summary">
        <div
          class="exams-list-item__info-summary-item"
          v-for="(info, infoIndex) in infoSummary"
          :key="infoIndex">
          <span class="exams-list-item__info-summary-item-label font-weight-bold">
            {{ info.label }}:
          </span>
          <span class="exams-list-item__info-summary-item-data ml-2">
            <!--
              Transforms text into lower case to be able to capitalize it through css for better
              looking. It should not be done if someday the information become case sensitive.
            -->
            {{ info.data.toLowerCase() }}
          </span>
        </div>
      </div>
      <div class="exams-list-item__actions-wrapper justify-sm-start">
        <exams-list-action-btn
          data-testid="exams-list-action-btn"
          v-for="(action, i) in getExamActions"
          :key="i"
          :id-process="`${processData.id}`"
          :action-type="action"
          :service-type="processData.exame.nome" />
      </div>
    </div>
  </div>
</template>

<script>
import EXAM_ACTION_TYPES from '@/utils/Enums/ExamActionTypes.js';
import SERVICE_TYPES from '@/utils/Enums/ServiceTypes.js';
import ExamsListActionButton from '@/components/ExamsList/ExamsListActionButton.vue';

export default {
  name: 'exams-list-item',
  components: {
    'exams-list-action-btn': ExamsListActionButton,
  },
  props: {
    processData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isExamStatusAvailable() {
      return this.processData.estado === 'resultado_disponivel';
    },
    formattedDateTimeConclusion() {
      if (!this.processData?.datahora_finalizacao) return 'Não informado';
      const date = new Date(this.processData.datahora_finalizacao);
      return date.toLocaleDateString('pt-BR', { dateStyle: 'long' });
    },
    getExamActions() {
      if (!this.isExamStatusAvailable) return [];

      const actions = [EXAM_ACTION_TYPES.VER_EXAME];

      if (this.canSeePrescription()) {
      // if (this.canSeePrescription() || true) { // DEV: REMOVER DEPOIS
        actions.push(EXAM_ACTION_TYPES.VER_PRESCRICAO);
      }

      return actions;
    },
    infoSummary() {
      return [
        { label: 'Paciente', data: this.processData.paciente.nome },
        { label: 'Solicitante', data: this.processData.perfil.usuario },
        { label: 'Unidade', data: this.processData.perfil.unidade },
        { label: 'Serviço', data: this.processData.exame.nome },
      ];
    },
  },
  methods: {
    canSeePrescription() {
      const { nome: type } = this.processData.exame;
      const hasPrescription = this.processData.possui_receita_oculos;

      if (SERVICE_TYPES.OFTALMOLOGIA === type && hasPrescription) return true;
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.exams-list-item {
  position: relative;
  display: block;
  margin-bottom: 1rem;
  background-color: #FFFFFF;
  color: map-get($material-light , 'text', 'primary');
  border-radius: 8px;
  box-shadow: rgba(0,0,0,.3) 0 3px 6px;
  padding: .875rem;
  &.exams-list-item--unavailable {
    opacity: .75;
    pointer-events: none;
    .exams-list-item__status-tag {
      .exams-list-item__status-tag-icon {
        background-color: var(--v-warning-base);
      }
    }
    .exams-list-item__content-wrapper {
      .exams-list-item__info-summary {
        width: 100%;
        margin-right: 0;
      }
    }
    .exams-list-item__actions-wrapper {
      display: none;
    }
  }
  .exams-list-item__process-tag {
    width: 33.3%;
  }
  .exams-list-item__status-tag {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.6875rem;
    line-height: 1;
    font-weight: bold;
    .exams-list-item__status-tag-icon {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: var(--v-success-base);
      display: inline-block;
    }
    .exams-list-item__status-tag-text {
      display: inline-block;
      vertical-align: top;
      margin-left: 22px;
    }
  }
  .exams-list-item__date-tag {
    position: absolute;
    bottom: .875rem;
    font-size: .625rem;
    .exams-list-item__date-tag-content {
      white-space: nowrap;
    }
  }
  .exams-list-item__content-wrapper {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    .exams-list-item__info-summary {
      width: calc(66% - 1rem);
      margin-right: 1rem;
      padding-bottom: 2rem;
      .exams-list-item__info-summary-item {
        width: 100%;
        display: flex;
        margin-bottom: .3rem;
        .exams-list-item__info-summary-item-data {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          text-transform: capitalize;
        }
      }
    }
    .exams-list-item__actions-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      width: 34%;
    }
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .exams-list-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .exams-list-item__status-tag {
      font-size: 1rem;
    }
    .exams-list-item__date-tag {
      position: relative;
      bottom: auto;
      width: 33.3%;
      font-size: 1rem;
      text-align: right;
    }
    .exams-list-item__content-wrapper {
      margin-top: 2rem;
      .exams-list-item__info-summary {
        width: calc(80% - 1rem);
        display: flex;
        flex-wrap: wrap;
        align-self: center;
        padding-bottom: 0;
        .exams-list-item__info-summary-item {
          width: 50%;
          font-size: 1.125rem;
          margin-bottom: 1.25rem;
          &:nth-child(even) {
            padding-left: .5rem;
          }
        }
      }
      .exams-list-item__actions-wrapper {
        width: 20%;
      }
    }
  }
}
</style>
